import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { changeLocale, injectIntl, Link, useIntl } from "gatsby-plugin-intl";
import SVG from "react-inlinesvg";
import {
  Contentful_Article,
  Contentful_ResearchArea,
  Contentful_Sector,
  Contentful_SiteSettings,
  Contentful_StaffMember,
} from "graphql-types";
import { ContentTypeColour, ThemeContext } from "../contexts/ThemeContext";
import Layout from "../layouts/layout";
import Image from "../components/image";
import { CollectionItem } from "../components/article";
import Heading2 from "../components/rich-text-elements/heading-2";
import Tile from "../components/tile/entry";
import { TileSize } from "../components/tile/types";
import useTree, { CollectionType } from "../hooks/useTree";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import { useArticleQuery } from "../queries/articlesQuery";
import { setLocation } from "../utils/geo-location";
import { LocationProvider } from "../contexts/LocationContext";
import Cookies from "js-cookie";

function SeeMoreArticles({ url }: { url: string }) {
  return (
    <Link to={url} className="underline text-lg md:text-base font-medium whitespace-nowrap">
      See more
    </Link>
  );
}

function RelatedArticles({ staffMemberId }: { staffMemberId: string }) {
  const allArticles = useArticleQuery();
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);
  const seeMoreArticlesURL = `/${SiteSettings?.primaryCollection?.urlSlug}`;

  const articles = allArticles
    .filter((a) => a.credits && a.credits.some((x) => x?.contentful_id === staffMemberId))
    .slice(0, 3);

  if (!articles.length) return null;

  return (
    <div>
      <hr className="my-10" />
      <div className="flex justify-between items-center mb-14 lg:mb-24">
        <div>
          <Heading2>Related articles</Heading2>
        </div>
        <div className="hidden md:block">
          {SiteSettings?.primaryCollection && <SeeMoreArticles url={seeMoreArticlesURL} />}
        </div>
      </div>
      <div className="flex">
        <div className="md:grid md:grid-cols-3 lg:grid-cols-3 md:gap-6 w-full">
          {articles &&
            articles.length > 0 &&
            articles.map((result: any, key: number) => {
              const entry: Contentful_Article | any = {
                __typename: "Contentful_Article",
                sys: result.sys.id,
                title: result.title,
                urlSlug: result.urlSlug,
                publishDate: result.publishDate ?? null,
                eventDate: result.eventDate ?? null,
                cardImage:
                  (result.cardImage && {
                    title: result.cardImage.title ?? "",
                    description: result.cardImage.description ?? "",
                    contentType: result.cardImage.contentType ?? "",
                    fileName: result.cardImage.fileName ?? "",
                    url: result.cardImage.url ?? "",
                    width: result.cardImage.width ?? "",
                    height: result.cardImage.height ?? "",
                  }) ??
                  null,
                contentType: result.contentType,
              };

              return <Tile entry={entry} size={TileSize.Tile} count={key} key={key} />;
            })}
        </div>
      </div>
      <div className="md:hidden text-center py-4">
        {SiteSettings.primaryCollection && <SeeMoreArticles url={seeMoreArticlesURL} />}
      </div>
    </div>
  );
}

const StaffMemberEntryTemplate = ({ data }: { data: any; pageContext: any }) => {
  const { defaultLocale } = useIntl();

  useEffect(() => {
    const currentLocation = Cookies.get("location");
    if (!currentLocation) {
      setLocation(defaultLocale).then(() => {
        changeLocale(defaultLocale);
      });
    }
  }, []);

  const pageData: Contentful_StaffMember = get(data, "contentful.staffMember");
  const sectors = useTree(CollectionType.sectors, pageData.sectorsCollection ?? null);
  const researchAreas = useTree(CollectionType.researchAreas, pageData.researchAreasCollection ?? null);
  const fullName = `${pageData.title ?? ""} ${pageData.firstName} ${pageData.lastName}`;
  const description =
    pageData?.bio && (pageData?.bio.length > 200 ? pageData?.bio?.substring(0, 200).concat("...") : pageData?.bio);
  return (
    <ThemeContext.Provider value={ContentTypeColour.Default}>
      <LocationProvider>
        <Layout
          title={fullName}
          metaDescription={description}
          metaImageUrl={pageData?.photo?.url ?? undefined}
          metaAuthor={fullName}
        >
          <main className="min-h-screen font-medium leading-tight bg-slate-100 text-slate-400">
            <div className="container mx-auto px-6 pt-20 pb-6 md:pt-32 lg:pt-40">
              <div className="leading-none pb-12 hidden md:block">
                <p className="text-base py-2">
                  {pageData.departmentsCollection?.items.map((department: any, key: number) => {
                    const l = pageData.departmentsCollection?.items.length ?? 1;
                    return (
                      <span key={key}>
                        {department?.label}
                        {key !== l - 1 && <span className="mx-2">·</span>}
                      </span>
                    );
                  })}
                </p>
                <h1 className="text-6xl py-2">{fullName}</h1>
                <h2 className="text-4xl py-2">{pageData.jobTitle}</h2>
              </div>
              <div className="md:flex md:flex-no-wrap">
                <div className="md:w-2/5">
                  <div className="md:pr-4">
                    <div className="block">
                      {pageData.photo && (
                        <Image image={pageData.photo} alt={fullName.trim() ?? ""} type={"fluid"} className="w-full" />
                      )}
                    </div>
                    <div className="md:hidden">
                      <p className="text-md py-2">
                        {pageData.departmentsCollection?.items.map((department: any, key: number) => {
                          const l = pageData.departmentsCollection?.items.length ?? 1;
                          return (
                            <span key={key}>
                              {department?.label}
                              {key !== l - 1 && <span className="mx-2">·</span>}
                            </span>
                          );
                        })}
                      </p>
                      <h1 className="text-5xl py-2">{fullName}</h1>
                      <h2 className="text-3xl py-2 leading-none">{pageData.jobTitle}</h2>
                    </div>
                    <div className="text-lg md:text-2xl py-6 md:py-12 break-words">
                      {pageData.phoneNumber && <p className="py-0">{pageData.phoneNumber}</p>}
                      {pageData.emailAddress && <p className="py-0">{pageData.emailAddress}</p>}
                    </div>
                  </div>
                </div>
                <div className="md:w-3/5">
                  <div className="md:pl-20">
                    {pageData.bio && (
                      <div>
                        <p className="font-normal text-lg py-6 md:py-0 leading-relaxed whitespace-pre-line">
                          {pageData.bio}
                        </p>
                      </div>
                    )}
                    <div>
                      {(pageData.orcidUrl || pageData.linkedInUrl) && (
                        <ul className="pt-8 pb-6">
                          {pageData.orcidUrl && (
                            <li className="pb-6">
                              <a href={pageData.orcidUrl} target="_blank" rel="noopener noreferrer">
                                <div className="flex font-medium leading-tight items-center">
                                  <div>
                                    <div
                                      className="flex mr-6 rounded-xl items-center justify-center bg-slate-200"
                                      style={{
                                        width: 48,
                                        height: 48,
                                      }}
                                    >
                                      <SVG
                                        src="/images/icons/orcid.svg"
                                        width="27"
                                        height="27"
                                        title=""
                                        className="text-slate-100"
                                        cacheRequests={true}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-2xl">Orcid profile</p>
                                    <p className="text-xs">Research history and publications</p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          )}

                          {pageData.linkedInUrl && (
                            <li className="pb-6">
                              <a href={pageData.linkedInUrl} target="_blank" rel="noopener noreferrer">
                                <div className="flex font-medium leading-tight items-center">
                                  <div>
                                    <div
                                      className="flex mr-6 rounded-xl items-center justify-center bg-slate-200"
                                      style={{
                                        width: 48,
                                        height: 48,
                                      }}
                                    >
                                      <SVG
                                        src="/images/icons/linkedin-no-border.svg"
                                        width="27"
                                        height="27"
                                        title=""
                                        className="text-slate-100"
                                        cacheRequests={true}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-2xl">LinkedIn profile</p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div>
                      {sectors && sectors.length > 0 && (
                        <ul className="pb-8">
                          <li>
                            <p className="font-medium text-3xl py-2 pb-4">Sectors</p>
                          </li>
                          {sectors.map((sector: Contentful_Sector, key: number) => {
                            if (!sector) return null;
                            return <CollectionItem item={sector} key={key} />;
                          })}
                        </ul>
                      )}

                      {researchAreas && researchAreas.length > 0 && (
                        <ul className="pb-8">
                          <li>
                            <p className="font-medium text-3xl py-2 pb-4">Research areas</p>
                          </li>
                          {researchAreas.map((researchArea: Contentful_ResearchArea, key: number) => {
                            if (!researchArea) return null;
                            return <CollectionItem item={researchArea} key={key} />;
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <RelatedArticles staffMemberId={pageData.sys.id} />
              </div>

              <div className={"flex-row items-start flex pb-10 mt-10"}>
                <Link
                  to={`/our-people`}
                  title={fullName.trim() ?? ""}
                  className={
                    "relative py-6 px-10 rounded-full content-center flex items-center justify-center bg-slate-400 text-white"
                  }
                >
                  Explore more people
                </Link>
              </div>
            </div>
          </main>
        </Layout>
      </LocationProvider>
    </ThemeContext.Provider>
  );
};

export default injectIntl(StaffMemberEntryTemplate);

export const pageQuery = graphql`
  query StaffMemberById($id: String!, $preview: Boolean!) {
    contentful {
      staffMember(id: $id, preview: $preview) {
        sys {
          id
          publishedVersion
        }
        firstName
        lastName
        title
        jobTitle
        urlSlug
        emailAddress
        phoneNumber
        bio
        orcidUrl
        linkedInUrl
        photo {
          sys {
            id
            spaceId
            environmentId
            publishedVersion
          }
          url
          title
          height
          width
          contentType
        }
        researchAreasCollection {
          items {
            sys {
              id
              publishedVersion
            }
            label
          }
        }
        sectorsCollection {
          items {
            sys {
              id
              publishedVersion
            }
            label
          }
        }
        departmentsCollection {
          items {
            sys {
              id
              publishedVersion
            }
            label
          }
        }
      }
    }
  }
`;
